// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: "development",

  googleSiteKey: '6Leb6VwkAAAAAPFbm0YX9tK7UYglY8ilegEIVpfZ',

  // localhost
  // apiUrl: 'http://localhost:4099/api',
  // loginUrl:'http://localhost:4099/api/Authentication/Login',
  // adminApiUrl: http://localhost:4099'/api/admin',

  // dev
  apiUrl: 'https://unido-emipapi.expertapps.com.sa/api',
  signalRUrl: 'https://unido-emipslr.expertapps.com.sa/hubs',
  loginUrl: 'https://unido-emipapi.expertapps.com.sa/api/Authentication/Login',
  adminApiUrl: 'https://unido-emipapi.expertapps.com.sa/api/admin',


  // demo
  // // demoUrl = 'https://unido-demoapi.expertapps.com.sa';
  // signalRUrl: 'https://unido-emiptestslr.expertapps.com.sa/hubs',
  // loginUrl:'https://unido-emiptestslrsn.expertapps.com.sa/api/Authentication/Login',
  // adminApiUrl: 'https://unido-emiptestslrsn.expertapps.com.sa/api/admin',


  // test
  // apiUrl: 'https://unido-emiptestapi.expertapps.com.sa/api',
  // signalRUrl: 'https://unido-emiptestslr.expertapps.com.sa/hubs',
  // loginUrl: 'https://unido-emiptestapi.expertapps.com.sa/api/Authentication/Login',
  // adminApiUrl: 'https://unido-emiptestapi.expertapps.com.sa/api/admin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
