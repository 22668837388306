import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { CasesService } from 'src/app/services/cases.service';
import Swal from 'sweetalert2';
export interface DialogData {
  caseId: any;
}
@Component({
  selector: 'app-final-feedback',
  templateUrl: './final-feedback.component.html'
})
export class FinalFeedbackComponent implements OnInit {
  formGroup: FormGroup;
  loading = false;
  isSubmited = false;
  dataLoading = false;
  deletedFiles: [] = [];
  files: [] = [];
  public Editor = ClassicEditor;
  EditorConfig = {
    toolbar: {
      items: [
        // 'bold', 'italic', 'strikethrough', 'underline', '|',
        // 'link', '|',
        // 'undo', 'redo'
      ],
      shouldNotGroupWhenFull: true
    },
    language: (document.documentElement.lang === 'en') ? 'en' : 'ar'
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<FinalFeedbackComponent>,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private casesService: CasesService
  ) {}

  ngOnInit(): void {
    console.log(this.data);

    this.buildForms();
  }

  buildForms() {
    this.formGroup = this.formBuilder.group({
      feedback: ['', [Validators.required]],
    });
  }
  updateFilesField($event: any) {
    this.files = $event;
    this.formGroup.controls.files.setValue(this.files);
  }
  hasError(formGroup: any, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }
  save() {
    this.isSubmited = true;
    this.isSubmited = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let obj: any = {
        caseId: +this.data,
        feedback: this.formGroup.controls.feedback.value,
      };
      this.casesService.addAdminFinalFeedback(obj).subscribe(
        (res: any) => {
          this.loading = false;
          this.isSubmited = false;
          this.dialogRef.close(true);
          Swal.fire({
            title: this.translate.instant('swal.success'),
            text: this.translate.instant('swal.addedsuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('swal.ok'),
          }).then(() => {});
        },
        (err: any) => {
          this.loading = false;
          this.isSubmited = false;
          console.log(err);
        }
      );
    }
  }

}
