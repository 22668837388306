import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SideBarComponent implements OnInit {
  @Input() toggleSide:any;
  @Output() sidebarTrigger = new EventEmitter();
  menuItems: any[] = [];
  // shouldOpenMenu = false;
  openRequestsSub = false;
  openProfileRequestsSub = false;
  openConfigSub = false;
  openInnerSub = false;
  unseenMessages: any;
  currentUser = new User();
  constructor(
    private lookupsService: LookupsService,
    private authenticationService: AuthenticationService,
    private router: Router) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.GetMenu();
  }

  // closeSubMenus() {
  //   this.menuItems.forEach(item => {
  //     item.openSub = false;
  //   });
  // }

  GetMenu() {
    this.lookupsService.GetMenu().subscribe(
      result => {
        this.menuItems = result as [];
        this.menuItems.map((item) => {
          item.openSub = false;
        });
      }, err => {
        console.error(err);
      });
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  initName(name:any) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

}
