import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { ExpertRegister } from '../models/auth/Expert-register';
import { EenterpriseRegister } from '../models/auth/Enterprise-register';
import { Config } from '../models/config';
import { Router } from '@angular/router';
import { User } from '../models/user/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private timeoutId: any;
  private inactiveTimeout = 7200000; // 7200000 2 hours (in milliseconds) // 300000 5 minutes (in milliseconds)
  private storageKey = 'userActivity';
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public authUser: any;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('adminUser')!)
    );

    this.currentUser = this.currentUserSubject.asObservable();
    window.addEventListener(
      'storage',
      () => {
        // debugger
        let adminUserStorage: any = localStorage.getItem('adminUser');
        const userToken = JSON.parse(adminUserStorage);
        // console.log(localStorage.getItem('adminUser'));
        if (
          (this.currentUserValue &&
            localStorage.getItem('adminUser') &&
            this.currentUserValue.accessToken !== userToken.accessToken) ||
          (this.currentUserValue && !localStorage.getItem('adminUser')) ||
          (!this.currentUserValue && localStorage.getItem('adminUser'))
        ) {
          this.router.navigate(['/']);
          window.location.reload();
          //this.logout()
        }
      },
      false
    );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: any, password: any, deviceId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // tslint:disable-next-line: max-line-length
    const code =
      encodeURIComponent(
        'qWAAq+lmFSIcn2NMVRfcwPHzHivAGWdfbrgoniWvbL/1DZ+izmy4vWDDFW6nLZNn'
      ) +
      '&client_secret=' +
      encodeURIComponent(
        'DPdU9mr4pHn3LYZu9jMfEvdP/fXCmsER5pABU4W4srApiFRGQHMvXEm/sBqcoVb1'
      );
    // tslint:disable-next-line: max-line-length

    const modal = {
      userName: email,
      password: password,
      deviceId: deviceId,
      secretId:
        'qWAAq+lmFSIcn2NMVRfcwPHzHivAGWdfbrgoniWvbL/1DZ+izmy4vWDDFW6nLZNn',
      secretKey:
        'DPdU9mr4pHn3LYZu9jMfEvdP/fXCmsER5pABU4W4srApiFRGQHMvXEm/sBqcoVb1',
    };
    // return this.http.post<any>(`${Config.loginUrl}`, 'username=' + email + '&password=' + password + '&grant_type=password&client_id=' + code, { headers })
    return this.http
      .post<any>(`${environment.loginUrl}`, modal, { headers })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          // console.log(user);
          this.authUser = user;
          this.getUserProfile();
          // if (user.userType ==1) {
          // }
          return user;
        })
      );
  }
  getUserProfile() {
    let user = this.currentUserValue;
    this.router.navigate(['/admin']);
    localStorage.setItem('adminUser', JSON.stringify(user));
    // this.http.get(`${environment.apiUrl}/Authentication/UserProfile`).pipe(first())
    //     .subscribe((data: any) => {
    //         user.email = data.email;
    //         user.fullName = data.fullName;
    //         user.userType = data.userType;
    //         localStorage.setItem('adminUser', JSON.stringify(user));
    //         this.currentUserSubject.next(user);
    //         window.location.reload();
    //         return user;
    //     },
    //     error => {
    //         //
    //     });
  }
  checkUniqueEmail(email: any, userId: any) {
    let user = '';
    if (this.currentUserValue && this.currentUserValue.accessToken) {
      user = '&userId=0';
    }
    if (userId) {
      user = '&userId=' + userId;
    }
    return this.http.get(
      `${environment.apiUrl}/SystemAdmin/ValidateEmail?email=` + email + user
    );
  }
  // checkUniqueEmail(email: any) {
  //     return this.http.get(`${environment.apiUrl}/SystemAdmin/ValidateEmail?email=` + email);
  // }
  checkUsername(username: any) {
    return this.http.get(
      `${environment.apiUrl}/account/ValidateUserName?filterKey=` + username
    );
  }
  registerExpert(user: ExpertRegister, files: any[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(user));
    return this.http.post(`${environment.apiUrl}/expert/register`, formData);
  }
  forgetPassword(email: any) {
    return this.http.get(
      `${environment.apiUrl}/Authentication/ForgetPassword?email=` + email
    );
  }
  resetPassword(userId: any, code: any, newPassword: any) {
    return this.http.post(
      `${environment.apiUrl}/Authentication/ResetPassword`,
      {
        userId,
        token: code,
        newPassword,
      }
    );
  }
  checkResetPasswordLink(userId: any, code: any) {
    return this.http.post(`${environment.apiUrl}/Authentication/VerifyToken`, {
      userId,
      token: code,
    });
  }

  resetTimer(): void {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => this.timerLogout(), this.inactiveTimeout);
    this.syncActivityState(true);
  }
  private syncActivityState(active: boolean): void {
    localStorage.setItem(this.storageKey, active ? 'active' : 'inactive');
  }

  private isUserActive(): boolean {
    const state = localStorage.getItem(this.storageKey);
    return state === 'active';
  }

  timerLogout() {
    this.signOut().subscribe((data) => {
      this.logout();
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('adminUser');
    localStorage.removeItem('rememberMe');
    this.currentUserSubject.next(null!);
    this.router.navigate(['/login']);
  }

  signOut() {
    return this.http.post(`${environment.apiUrl}/Authentication/SignOut`, {});
  }

  loginStatus() {
    return this.http.post(`${environment.apiUrl}/Authentication/loginStatus`, {});
  }

  activatedOrDeactivatedUsers(obj:any) {
    return this.http.put(`${environment.apiUrl}/Authentication/ActivatedOrDeactivatedUsers?loginStatus=${obj.loginStatus}0&userId=${obj.userId}`, {});
  }

  confrimEmail(userId: any, code: any) {
    return this.http.get(
      `${environment.apiUrl}/account/ConfirmEmail?userId=` +
        userId +
        '&code=' +
        encodeURIComponent(code)
    );
  }
  registerEnterprise(user: EenterpriseRegister, files: any[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(user));
    return this.http.post(
      `${environment.apiUrl}/enterprise/Register`,
      formData
    );
  }
}
