import { Injectable, EventEmitter, DebugElement } from '@angular/core';
declare var $: any;
import { Config } from '../models/config';
import { AuthenticationService } from './authentication.service';

import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRServiceService {
  // private messageHub: any;
  // SignalrConnection: any;
  // signalReceived = new EventEmitter<any>();
  // constructor(private authService: AuthenticationService) {

  // }

  // buildConnection() {
  //   const connection = $.hubConnection(environment.signalRUrl);
  //   this.messageHub = connection.createHubProxy('unidoHub');
  //   const token = this.authService.currentUserValue.accessToken;
  //   $.signalR.ajaxDefaults.headers = { Authorization: 'Bearer ' + token };
  //   connection.start()
  //     .done(() => {
  //       console.log('connection started ..........');
  //     })
  //     .fail(() => {
  //       console.log('connection failed');
  //     });
  // }

  private hubConnection: signalR.HubConnection;

  constructor(private authService: AuthenticationService) { }

  public startConnection = () => {
    // const options: IHttpConnectionOptions = {
    //   accessTokenFactory() {
    //     return this.authService.currentUserValue.token;
    //   }
    // };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRUrl, {
        skipNegotiation: true,
        transport:
          signalR.HttpTransportType.WebSockets ||
          signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => this.authService.currentUserValue.accessToken,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started ...');
      })
      .catch((err) =>
        console.log('Error while starting connection ... ' + err)
      );
  };
  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => console.log('Connection stopped ...'))
        .catch((err) =>
          console.log('Error while stopping connection ... ' + err)
        );
    }
  }

  sendAlert = (context: any, handleFunc: any) => {
    this.hubConnection.on('sendAlert', (data) => {
      handleFunc(context, data);
    });
  };
  disposeAlert = (context: any, handleFunc: any) => {
    this.hubConnection.on('disposeAlert', (taskGuid) => {
      handleFunc(context, taskGuid);
    });
  };
  changeNotificationCount = (context: any, handleFunc: any) => {
    this.hubConnection.on('updateNotifications', (count) => {
      handleFunc(context, count);
    });
  };


  handleProfileUpdatedEvent(context: any, handleFunc: any) {
    this.hubConnection.on('profileUpdated', (profileId: any) => {
      handleFunc(context, profileId);
    });
  }
  handleNewEnterpriseRequestEvent(context: any, handleFunc: any) {
    this.hubConnection.on('newEnterpriseRequest', () => {
      handleFunc(context);
    });
  }
  handleNewExpertRequestEvent(context: any, handleFunc: any) {
    this.hubConnection.on('newExpertRequest', () => {
      handleFunc(context);
    });
  }
  handleNewExpertUpdateProfileRequestEvent(context: any, handleFunc: any) {
    this.hubConnection.on('newExpertUpdateProfileRequest', () => {
      handleFunc(context);
    });
  }
  handleExpertMissionDecision(context: any, handleFunc: any) {
    this.hubConnection.on('expertMissionDecision', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handleCaseUpdated(context: any, handleFunc: any) {
    this.hubConnection.on('caseUpdated', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handleCaseUpdatedNew(context: any, handleFunc: any) {
    this.hubConnection.on('caseUpdated1', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handleNotifyingFBKPIsAnswering(context: any, handleFunc: any) {
    this.hubConnection.on('notifyingFBKPIsAnswering', () => {
      handleFunc(context);
    });
  }
  handleNotifyingFBAppFilledSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('notifyingFBAppFilledSignlar', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handleRefreshEnterprisesSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('refreshEnterprises', () => {
      handleFunc(context);
    });
  }
  handleRefreshExpertsSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('refreshExperts', () => {
      handleFunc(context);
    });
  }
  handleMissionRequestUpdatedSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('missionRequestUpdated', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handleRequestUpdatedSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('requestUpdated', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handleUserAssigedToProjectSignlar(context: any, handleFunc: any) {
    this.hubConnection.on('userAssigedToProject', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handlecapturedImageUpdated(context: any, handleFunc: any) {
    this.hubConnection?.on('capturedImageUpdated', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handlexpertMissionAccepted(context: any, handleFunc: any) {
    this.hubConnection?.on('expertMissionAccepted', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handlenterpriseUpdated(context: any, handleFunc: any) {
    this.hubConnection?.on('enterpriseUpdated', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  //   missionRequestUpdated
  // expertMissionDecision
  logout = (context: any, handleFunc: any) => {
    this.hubConnection.on('logout', () => {
      handleFunc(context);
    });
  };
}
